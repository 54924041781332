import useSWR from "swr";
import { getRequest } from "../../api/getRequest";
import config from "../../constants/config";

/**
 * Get projects for this user from the API
 * 
 * @param {Number} projectId If specified, only the project with the specified project id will be fetched
 * @param {Boolean} next If true, the next page of projects will be fetch
 * @returns API response
 */
export function useProjects(projectId = null, next = false) {
  let url = `${config.endpoints.projects}?pagination=keyset&per_page=${config.resultsPerPage}&order_by=id&sort=asc`;
  if (projectId) url = `${config.endpoints.projects + "/" + projectId}`;
  if (next && projectId) {
    url = `${config.endpoints.projects}/next/${projectId}/${config.resultsPerPage}`;
  }
  const { data: response } = useSWR(
    {
      endpoint: url,
    },
    getRequest
  );
  const apiData = response?.data;
  const apiError = response?.error;
  return {
    data: apiData,
    error: apiError,
  };
}
