import Cookies from "js-cookie";
import config from "../../constants/config";
import { getIssues } from "./useIssues";
import { getMilestone } from "./useMilestones";
import { getRequest } from "../../api/getRequest";
import { useIntersectionObserver } from "../useIntersectionObserver";
import { useState } from "react";

export function useNextProjects() {
  const [requesting, setRequesting] = useState(false);
  const projectId = Cookies.get("next");
  const [nextProject, setNextProject] = useState({ data: null, error: null });
  const [nextMilestones, setNextMilestones] = useState({
    data: null,
    error: null,
  });
  const [nextIssues, setNextIssues] = useState({
    data: null,
    error: null,
  });

  const [nextPage, setNextPage] = useState(null);
  const ref = useIntersectionObserver(async (entries) => {
    // const { intersectionRatio } = entries?.[0];
    if (
      /* intersectionRatio !== 1 && */ projectId &&
      nextPage !== projectId &&
      !requesting
    ) {
      setRequesting(true);
      let data = null,
        error = null;
      try {
        ({ data, error } = await getRequest({
          endpoint: `${config.endpoints.projects}/next/${projectId}/${config.resultsPerPage}`,
        }));
      } catch (e) {
        console.error(e);
      }
      let milestonesData;
      if (data) {
        try {
          milestonesData = await getMilestone(data);
          setNextMilestones(milestonesData);
          // console.log({ milestonesData: milestonesData });
        } catch (e) {
          console.error(e);
          setNextMilestones({ data: null, error: e });
        }
      }
      let issuesData;
      if (milestonesData) {
        try {
          issuesData = await getIssues(milestonesData.data);
          setNextIssues(issuesData);
        } catch (e) {
          console.error({ data: null, error: e });
        }
      }
      if (data || (error && milestonesData && issuesData)) {
        setRequesting(false);
      }
      setNextProject({ data, error });
      setNextPage(projectId);
    }
  });

  const { data, error } = nextProject;
  const { data: nextMilestonesData, error: nextMilestonesError } =
    nextMilestones;
  const { data: nextIssuesData, error: nextIssuesError } = nextIssues;
  return {
    ref,
    data,
    error,
    nextMilestonesData,
    nextMilestonesError,
    nextIssuesData,
    nextIssuesError,
  };
}
