import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

export function ButtonRadioGroup({
  values,
  onChange,
  defaultValue,
  orientation = "horizontal",
}) {
  const [active, setActive] = useState(defaultValue);

  useEffect(() => {
    setActive(defaultValue);
  }, [defaultValue]);
  return (
    <ButtonGroup
      orientation={orientation}
      variant="contained"
      aria-label="outlined primary button group"
    >
      {values.map(({ text, value }) => (
        <Button
          key={value}
          disabled={active === value}
          onClick={() => {
            onChange(value);
            setActive(value);
          }}
        >
          {text}
        </Button>
      ))}
    </ButtonGroup>
  );
}
