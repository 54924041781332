export function buildMilestones({ projects, allMilestones, allIssues }) {
  if (projects && allMilestones && allIssues) {
    return projects.map((project, index) => {
      const milestones = allMilestones?.[index];
      const issues = allIssues?.[index];
      return milestones?.data
        ?.map((milestone, index) => {
          const milestoneTitle = milestone?.title;
          const issuesForMilestone = issues?.[index]?.data;
          const numberOfIssues = issuesForMilestone?.length || 0;

          const numberOfClosedIssues =
            issuesForMilestone?.filter((issue) => {
              // issue state can either be 'opened' or 'closed'
              return issue?.state === "closed";
            }).length || 0;
          const milestoneProgress = Math.round(
            (numberOfClosedIssues / numberOfIssues) * 100
          );
          return {
            name: `Project ${project?.name} | Milestone: ${milestoneTitle}`,
            id: `milestone_${milestoneTitle}`,
            start: milestone?.start_date ? new Date(milestone.start_date) : null,
            end: milestone?.due_date ? new Date(milestone.due_date) : null,
            progress: isNaN(milestoneProgress) ? 0 : milestoneProgress,
            dependencies: "",
            projectId: project?.id,
            url: milestone.web_url,
          };
        })
        .filter((data) => data.start);
    });
  }
}
