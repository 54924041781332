import React, { useEffect } from "react";
import Cookies from "js-cookie";

const Login = () => {
  useEffect(() => {
    const { redirect } = Cookies.get();
    window.location.href = redirect;
  }, []);
  return <p>Redirecting ...</p>;
};

export default Login;
