import useSWR from "swr";
import { getRequest } from "../../api/getRequest";
import config from "../../constants/config";

export function useMilestones(projects) {
  // console.log({ milestones_proj: projects });
  const { data: response } = useSWR([projects], getMilestone);
  const apiData = response?.data;
  const apiError = response?.error;
  // console.log({ milestones_api: apiData });
  return {
    data: apiData,
    error: apiError,
  };
}

export async function getMilestone(projects) {
  // console.log({ milestones_gm: projects });
  if (Array.isArray(projects) /*  && projects?.[0]?.length */) {
    try {
      const data = await Promise.all(
        projects.map(async (project) => {
          return await getRequest({
            endpoint: `${config.endpoints.projects}/${project?.id}/milestones?pagination=keyset&per_page=${config.resultsPerPage}&order_by=id&sort=asc`,
          });
        })
      );
      return data?.[0]?.error ? { error: data } : { data };
    } catch (e) {
      console.log({ e });
      return { error: e };
    }
  }
}
