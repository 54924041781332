export default function removeDuplicateMilestones(array) {
  const stringifiedData = array.map((data) =>
    data ? JSON.stringify(data) : data
  );
  const uniqueData = Array.from(new Set(stringifiedData)).map((data) => {
    const parsedData = data ? JSON.parse(data) : data;
    return parsedData
      ? {
          ...parsedData,
          end: new Date(parsedData.end),
          start: new Date(parsedData.start),
        }
      : data;
  });
  return uniqueData;
}
