import { useEffect, useRef } from "react";

const defaultCallback = function (entries) {
  if (entries[0].intersectionRatio < 0) return;
};

const defaultIntersectoinOptions = {
  root: null, // null means use viewport, it could be another DOM element
  rootMargin: "16px",
  threshold: [0.01, 0.99], // invoke callback at 1% and 99%
};

export function useIntersectionObserver(
  callback = defaultCallback,
  intersectoinOptions = defaultIntersectoinOptions
) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      callback,
      intersectoinOptions
    );
    intersectionObserver.observe(wrapperRef.current);
    return () => {
      intersectionObserver.disconnect();
    };
  }, [wrapperRef, intersectoinOptions, callback]);
  return wrapperRef;
}
