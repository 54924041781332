import React, { useEffect, useState } from "react";

import { ButtonRadioGroup } from "../../components/ButtonRadioGroup/ButtonRadioGroup";
import { CssWrapper } from "./HomeScreen-style";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import GantChart from "../../components/GantChart";
import config from "../../constants/config";
import { useIssues } from "../../hooks/api_hooks/useIssues";
import { useMilestones } from "../../hooks/api_hooks/useMilestones.js";
import { useNextProjects } from "../../hooks/api_hooks/useNextProjects";
import { useProjects } from "../../hooks/api_hooks/useProjects";

const HomeScreen = () => {
  const [allProjects, setAllProjects] = useState();
  const [allIssues, setAllIssues] = useState();
  const [allMilestones, setAllMilestones] = useState();
  const {
    ref: wrapperRef,
    data: nextProjects,
    error: nextProjectsError,
    nextMilestonesData,
    nextMilestonesError,
    nextIssuesData,
    nextIssuesError,
  } = useNextProjects();

  const { data: projects, error: projectError } = useProjects();
  const { data: milestones, error: milestonesError } = useMilestones(projects);
  const { data: issues, error: issuesError } = useIssues(milestones);
  const [activeMode, setActiveMode] = useState(config.defaultMode);
  const [showList, setShowList] = useState(config.defaultShowList);

  useEffect(() => {
    setAllProjects([...(allProjects || []), ...(nextProjects || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextProjects]);

  useEffect(() => {
    setAllMilestones([...(allMilestones || []), ...(nextMilestonesData || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextMilestonesData]);

  useEffect(() => {
    setAllIssues([...(allIssues || []), ...(nextIssuesData || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextIssuesData]);

  const modes = config.viewModes.map((mode) => ({ text: mode, value: mode }));
  const showListValues = [
    { text: "Show Milestones List", value: true },
    { text: "Hide Milestones List", value: false },
  ];
  
  return (
    <ErrorBoundary>
      <CssWrapper>
        <main>
          <section className="buttonGroup__wrapper">
            <ButtonRadioGroup
              defaultValue={config.defaultMode}
              onChange={setActiveMode}
              values={modes}
            />
          </section>
          <section ref={wrapperRef}>
            <div className="buttonGroup__wrapper">
              <ButtonRadioGroup
                defaultValue={showList}
                onChange={setShowList}
                values={showListValues}
              />
            </div>
            <GantChart
              // projectData={{
              //   projects: [...(projects || []) /* , ...allProjects */],
              //   projectError: projectError || nextProjectsError,
              // }}
              // milestonesData={{ milestones, milestonesError }}
              // issuesData={{ issues, issuesError }}
              projectData={{
                projects: [...(projects  || []), ...(allProjects || [])],
                projectError: projectError,
              }}
              milestonesData={{
                milestones: [...(milestones || []), ...(allMilestones || [])],
                milestonesError,
              }}
              issuesData={{
                issues: [...(issues || []), ...(allIssues || [])],
                issuesError,
              }}
              mode={activeMode}
              showList={showList}
            />
          </section>
        </main>
      </CssWrapper>
    </ErrorBoundary>
  );
};

export default HomeScreen;
