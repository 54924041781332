import useSWR from "swr";
import { getRequest } from "../../api/getRequest";
import config from "../../constants/config";

export function useIssues(milestones) {
  const { data: response } = useSWR([milestones], getIssues);
  const apiData = response?.data;
  const apiError = response?.error;
  return {
    data: apiData,
    error: apiError,
  };
}

export async function getIssues(milestones) {
  if (Array.isArray(milestones)) {
    try {
      const data = await Promise.all(
        milestones.map(async (milestone) => {
          if (milestone?.data) {
            return await Promise.all(
              milestone.data.map(async (issue) => {
                return await getRequest({
                  endpoint: `${config.endpoints.projects}/${issue?.project_id}/milestones/${issue?.id}/issues?pagination=keyset&per_page=${config.resultsPerPage}&order_by=id&sort=asc`,
                });
              })
            );
          }
        })
      );
      return { data };
    } catch (e) {
      return { error: e };
    }
  }
}
