import "gantt-task-react/dist/index.css";

import React, { useEffect } from "react";
import { Gantt, ViewMode } from "gantt-task-react";

import { buildMilestones } from "../clientUtils/buildMilestones";
import config from "../constants/config";
import removeDuplicateMilestones from "../clientUtils/removeDuplicateMilestones";

export default function GantChart({
  mode,
  projectData,
  milestonesData,
  issuesData,
  showList,
}) {
  const { projects, projectError } = projectData;
  const { milestones, milestonesError } = milestonesData;
  const { issues, issuesError } = issuesData;
  const gantChartData = buildMilestones({
    projects: projects,
    allMilestones: milestones,
    allIssues: issues,
  });

  useEffect(() => {
    if (config.autoScrollToToday) {
      const today = document.querySelector(".today");
      const scroller = document.querySelector('[dir="ltr"]');
      // eslint-disable-next-line no-unused-expressions
      scroller?.scrollBy(
        today.getBoundingClientRect().x -
          (today.getBoundingClientRect().x ? window.innerWidth / 2 : 0),
        0
      );
    }
  }, [issues]);

  const handleDbClick = (task) => {
    window.open(task.url, null, "noopener, noreferrer");
  };

  const uniqueData = removeDuplicateMilestones(gantChartData?.flat());

  const error = projectError || milestonesError || issuesError;
  if (error)
    return (
      <>
        <div>
          {Array.isArray(error) ? (
            <>
              <h1>{error[0].error?.response?.statusText}</h1>
              {error[0].error?.response?.status === 401 && (
                <h1>
                  Please <a href={"/login"}>Login</a> to continue
                </h1>
              )}
            </>
          ) : (
            <>
              <h1>{error?.response?.statusText}</h1>
              {error?.response?.status === 401 && (
                <h1>
                  Please <a href={"/login"}>Login</a> to continue
                </h1>
              )}
            </>
          )}
        </div>
      </>
    );
  if (!projects?.length || !milestones?.length || !issues?.length)
    return <h1>Loading!!!</h1>;
  try {
    return gantChartData && uniqueData ? (
      <div className="gantt-wrapper">
        <Gantt
          tasks={uniqueData || []}
          viewMode={mode}
          onDateChange={(obj) => console.log(obj)}
          onClick={(obj) => console.log(obj)}
          onDelete={(obj) => console.log(obj)}
          onProgressChange={(obj) => console.log(obj)}
          onDoubleClick={(obj) => handleDbClick(obj)}
          onSelect={(obj) => console.log(obj)}
          onExpanderClick={(obj) => console.log(obj)}
          listCellWidth={showList ? "155px" : ""}
          columnWidth={config.columnWidth}
          rowHeight={48 * config.rowScaleFactor}
        />
      </div>
    ) : null;
  } catch (e) {
    console.error(e);
    return <h1>Error occured ☹</h1>;
  }
}
