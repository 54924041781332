import React from "react";

import { NavBar } from "@apd/navbar";
import { Footer } from "@apd/footer";
import { BrowserRouter } from "react-router-dom";
import c from "../constants/config";
import { CssMain, CssBody, GlobalStyle } from "./App-Style";
import Router from "../router/router";
import navigationData from "../constants/navigation";
import { AppProvider } from "../context";

const App = ({ props }) => {
  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyle />
        <ContentWrapper {...props} />
      </AppProvider>
    </BrowserRouter>
  );
};

const ContentWrapper = () => (
  <CssBody>
    <NavBar data={navigationData} name="appname" />
    <CssMain>
      <Router />
    </CssMain>
    <Footer appName="appname" appVersion={c.app_version} />
  </CssBody>
);

export default App;
