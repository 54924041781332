import styled from "styled-components";

export const CssWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .buttonGroup__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  main {
    width: 100%;
  }
`;
