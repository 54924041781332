import axios from "axios";
import Cookies from "js-cookie";

export async function getRequest({ endpoint }) {
  const token = Cookies.get("token");
  const config = token
    ? {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      }
    : null;
  try {
    const response = await axios.get(endpoint, config);
    const { data } = response;
    return { data };
  } catch (error) {
    console.error({ error });
    return { error };
  }
}
