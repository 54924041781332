const config = {
  map: {
    zoom: 13,
    center: [41.156608, -8.614529],
    tile: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
  },
  api: {},
  app_version: `${window?.bootstrap.APP_VERSION}`,
  viewModes: ["Quarter Day", "Half Day", "Day", "Week", "Month"],
  defaultMode: "Week",
  defaultShowList: false,
  autoScrollToToday: true,
  columnWidth: 50,
  rowScaleFactor: 5 / 3, // Do not change this, it depends on the gantt library.
  // Use environment variable REACT_APP_PER_PAGE or fallback to 100 - the maximum possible
  resultsPerPage: process.env.REACT_APP_PER_PAGE || 100, // maximum is 100
  endpoints: {
    projects: "/_api/projects",
  },
};

export default config;
