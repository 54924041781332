import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
        margin        : 0;
        padding       : 0;
        font-family   : "Roboto";
        background-color: ${props => props.theme.colors.bg.body.default};
    }
    `;

export const CssMain = styled.div`
  flex: 1;
  position: relative;
`;

export const CssBody = styled.div`
  font-family: "Roboto";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
