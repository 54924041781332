import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    console.error({ error });
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo, hasError: this.state.hasError });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Sorry! an error occured</h1>;
    }

    return this.props.children;
  }
}
