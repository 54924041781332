import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import HomeScreen from "../screens/HomeScreen/HomeScreen";
import Login from "../screens/Login/Login";

const Router = () => (
  <>
    <Suspense fallback={<div>Loading...</div>}></Suspense>
    <Switch>
      <Route path="/login" component={Login} />
      <Route exact path="/home" component={HomeScreen} />
      <Redirect from="/" to="/home" />
    </Switch>
  </>
);

export default Router;
